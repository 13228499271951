export class Client {
    public id?: string;
    public logo_white?: string;
    public logo_dark?: string;
    public icon_white?: string;
    public icon_dark?: string;
    public favicon?: string;

    public date_start?: string;
    public date_process?: string;
    public date_completed?:string;

    public name?: string;
    public email?: string;
    public phone?: string;
    public facebook?: string;
    public instagram?: string;
    public twitter?: string;
    public business?: string;
    public status?: string;
    public plan?: string;
    public design?: string;
    public primary_color?: string;
    public secondary_color?: string;
    public background_color?: string;
    public text_background?: string;
    public text_primary?: string;
    public text_secondary?: string;

    public slogan?: string;
    public mission?: string;
    public vision?: string;
    public objective?: string;

    public design_main?: string;
    public design_search?: string;
    public design_contact?: string;
    public design_extra?: string;
    
    constructor( clientObj: ClientObj) {
        this.id = clientObj && clientObj.id || undefined;
        this.name = clientObj && clientObj.name || undefined;
        this.logo_white = clientObj && clientObj.logo_white || undefined;
        this.logo_dark = clientObj && clientObj.logo_dark || undefined;
        this.icon_white = clientObj && clientObj.icon_white || undefined;
        this.icon_dark = clientObj && clientObj.icon_dark || undefined;
        this.favicon = clientObj && clientObj.favicon || undefined;

        this.date_start = clientObj && clientObj.date_start || undefined;
        this.date_process = clientObj && clientObj.date_process || undefined;
        this.date_completed = clientObj && clientObj.date_completed || undefined;

        this.email = clientObj && clientObj.email || undefined;
        this.phone = clientObj && clientObj.phone || undefined;
        this.facebook = clientObj && clientObj.facebook || undefined;
        this.instagram = clientObj && clientObj.instagram || undefined;
        this.twitter = clientObj && clientObj.twitter || undefined;

        this.business = clientObj && clientObj.business || undefined;
        this.status = clientObj && clientObj.status || undefined;
        this.plan = clientObj && clientObj.plan || undefined;
        this.design = clientObj && clientObj.design || undefined;
        this.primary_color = clientObj && clientObj.primary_color || undefined;
        this.secondary_color = clientObj && clientObj.secondary_color || undefined;
        this.background_color = clientObj && clientObj.background_color || undefined;
        this.text_background = clientObj && clientObj.text_background || undefined;
        this.text_primary = clientObj && clientObj.text_primary || undefined;
        this.text_secondary = clientObj && clientObj.text_secondary || undefined;

        this.slogan = clientObj && clientObj.slogan || undefined;
        this.mission = clientObj && clientObj.mission || undefined;
        this.vision = clientObj && clientObj.vision || undefined;
        this.objective = clientObj && clientObj.objective || undefined;

        this.design_main = clientObj && clientObj.design_main || undefined;
        this.design_search = clientObj && clientObj.design_search || undefined;
        this.design_contact = clientObj && clientObj.design_contact || undefined;
        this.design_extra = clientObj && clientObj.design_extra || undefined;
    }
}

export interface ClientObj {
    id?: string
    name?: string
    logo_white?: string
    logo_dark?: string
    icon_white?: string
    icon_dark?: string
    favicon?: string

    date_start?: string;
    date_process?: string;
    date_completed?:string;


    email?: string
    phone?: string
    facebook?: string
    instagram?: string
    twitter?: string
    business?: string
    status?: string
    plan?: string
    design?: string;
    primary_color?:string
    secondary_color?:string
    background_color?:string
    text_background?: string
    text_primary?: string
    text_secondary?: string


    slogan?: string
    mission?: string
    vision?: string
    objective?: string

    design_main?: string
    design_search?: string
    design_contact?: string
    design_extra?: string


}