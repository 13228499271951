export let footer_css_3 = `
.footer-subcontainer{
    background: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 40px 0px 60px 0px;
}


.footer-subcontainer > *{
    color: white;
}

.footer-border-image {
    display: none;
}

.footer-icon{
    margin-right: 10px;
}

.footer-title-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}



.footer-title{
    margin: 0px;
}

.footer-email{
    text-align: center;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}


.footer-phone{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-phone a{
    margin: 0px 10px !important;
    color: white;
}

.footer-social-container{
    display: flex;
    justify-content: center;
    /* margin: 0px 10px !important; */
    width: 100%;
}

.footer-social-container > a{ 
    color: white;
}


.footer-social{
    margin: 10px;
}


@media (min-width: 576px) {
    
}

@media (min-width: 768px) {
    .footer-phone{
        margin-bottom: 0px !important;
    }

}

@media (min-width: 992px) {
    .footer-phone{
        flex-wrap: nowrap;
    }

    .footer-social-container{
        width: 50%;
    }
    
    .footer-phone{
        width: 50%;
    }
    
    .footer-title-container{
        width: 50%;
    }
    
    .footer-email{
        width: 50%;
    }
}

@media (min-width: 1200px) {

}
`;