import { ActionReducerMap } from '@ngrx/store';

import * as fromPages from './pages/pages.reducer';
import * as fromComponents from './pages/component/components.reducer';
import * as fromUI from './ui/ui.reducer';

export interface AppState {
    orders: fromPages.AccountState;
    components: fromComponents.AccountState,
    ui: fromUI.uiState
}

export const appReducers: ActionReducerMap<AppState> = {
    orders: fromPages.accountReducer,
    components: fromComponents.accountReducer,
    ui: fromUI.uiReducer
}