import { Action } from '@ngrx/store';
import { Client } from '../models/client.model';

export const SET_CLIENT = '[Order] Set Client';
export const UNSET_CLIENT = '[Order] Remove Client';

export class SetClientAction implements Action {
    readonly type = SET_CLIENT;

    constructor( public client: Client ){}
}

export class UnsetClientAction implements Action {
    readonly type = UNSET_CLIENT;
}

export type acciones = SetClientAction | UnsetClientAction;

