import { Action } from '@ngrx/store';

export const SET_LOADING_PORCENTAGE = '[UI] Set loading status';
export const SET_MENU_STATUS = '[UI] Set menu status';

export class SetUILoadingAction implements Action {
    readonly type = SET_LOADING_PORCENTAGE;

    constructor( public loading: number ){}
}

export class SetMenuStatusAction implements Action {
    readonly type = SET_MENU_STATUS;

    constructor( public menu: boolean ){}
}

export type acciones = SetUILoadingAction | SetMenuStatusAction;

