// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBSFe50NpqtD19zjLjziEapxXzN1a58S7U",
    authDomain: "hogar-inn.firebaseapp.com",
    databaseURL: "https://hogar-inn.firebaseio.com",
    projectId: "hogar-inn",
    storageBucket: "hogar-inn.appspot.com",
    messagingSenderId: "981452844908",
    appId: "1:981452844908:web:e402ab08275d1a06"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
