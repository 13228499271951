import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { WebpagesComponent } from './pages/webpages/webpages.component';

// Http
import { HttpClientModule } from '@angular/common/http';

//Routes
import { AppRoutesModule } from './app.routes';

// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './app.reducer';
import { environment } from 'src/environments/environment';
import { CotizadorComponent } from './pages/cotizador/cotizador.component';

// Firebase Storage
import {  AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireModule } from 'angularfire2';
import { TemplatesComponent } from './pages/templates/templates.component';
import { NavbarComponent } from './pages/shared/navbar/navbar.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LoginComponent } from './pages/shared/login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    WebpagesComponent,
    CotizadorComponent,
    TemplatesComponent,
    OrdersComponent,
    LoginComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    StoreModule.forRoot( appReducers ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    AngularFireModule.initializeApp( environment.firebaseConfig ),
    AngularFireStorageModule, 
    AngularFireDatabaseModule,
    AppRoutesModule
  ],
  exports: [
 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
