import { Action } from '@ngrx/store';

export const SET_DESIGN_SELECTED = '[Components] Set design selected for the gallery templates';

export class SetDesignSelectedAction implements Action {
    readonly type = SET_DESIGN_SELECTED;

    constructor( public design: string ){}
}


export type acciones = SetDesignSelectedAction;

