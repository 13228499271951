export let index_css_3 = `

html {
    font-size: 1rem !important;
    font-family: 'Quicksand', sans-serif;
}

html > * {
    font-family: 'Quicksand', sans-serif;
    font-weight: lighter;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;  /* Non-prefixed version, currently
            supported by Chrome and Opera */
}

#body{
    padding-top: 20px;
    padding-bottom: 100px;
}

#banner{
    display: flex;
    flex-wrap: wrap;
}

.card{
    color: black;
}

.banner-brand{
    height: 100%;
}

.banner-brand-container{
    width: 55%;
    display: flex;
    align-items: center;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

.banner-brand-subcontainer{
    display: flex;
    justify-content: flex-end;
    width: 95%;
    height: 100px;
    padding: 10px;
}

.banner-image-container{
    width: 45%;
    height: 200px;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


.banner-image-subcontainer{
    height: 100%;
}

.banner-image{
    /* -webkit-filter: grayscale(60%);
    filter: grayscale(60%); */
    height: 100%;
    object-fit: cover;
}

.banner-slogan-container{
    order: 3;
    width: 100%;
    margin-top: 10px;
    text-align: center;
    color: white;

    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    
}

.lead{
    margin-top: 1rem;
}


@media (min-width: 576px) {
    .banner-image-container{
        width: 35%;
        height: 300px;
    }

    .banner-brand-container{
        width: 65%;
    }

    .lead{
        font-size: 1.2rem;
        
    }

    .banner-brand-subcontainer{
        height: 120px;
    }

    .card-columns{
        column-count: 2 !important;
        -webkit-column-count: 2 !important;
        -moz-column-count: 2 !important;
    }
    
}

@media (min-width: 768px) {
    .banner-image-container{
        height: 70vh;
    }

    .lead{
        font-size: 1.5rem;
    }

    .banner-brand-subcontainer{
        height: 160px;
    }

    
}

@media (min-width: 992px) {

    .banner-slogan-container{
        margin-top: 40px;
    }

    .lead{
        font-size: 1.8rem;
    }

    .card-columns{
        column-count: 3 !important;
        -webkit-column-count: 3 !important;
        -moz-column-count: 3 !important;
    }
}

@media (min-width: 1200px) {

}

/* SEARCH */
#search{
    margin-top: 40px;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.search-title{
    text-align: left;
    margin-bottom: 25px
}



@media (min-width: 576px) {
    .search-container{
        padding: 20px  0px 10px 0px;
    }

    .search-button{
        padding: 0px 5px;
    }

    #search{
        margin-top: 20px;
    }
    
}

@media (min-width: 768px) {
    #search{
        margin-top: 40px;
    }
    
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

/* COMPANY */
#company-btn{
    margin-top: 50px;
    padding: 10px 0px 0px 0px;
}

.company-buttons-container{
    display: flex;
    justify-content: space-around;
    /* padding: 10px 20px; */
    background: white;
    color: black;
}

.company-buttons-container .button{
    font-size: 1rem;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
}

.company-buttons-container .button.active{
    background-color: #d8d8d8;
}


.company-content{
    font-size: 1rem;
    text-align: justify;
    padding: 20px 20px;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

    /* #company-btn{
        margin-top: 35px;
    } */

    .company-buttons-container .button{
        font-size: 1.5rem;
    }

    .company-content{
        font-size: 1.3rem;
    }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

/* TOP */
#top{
    margin-top: 120px;
}

#top.top2 .top-title{
    display: none;
}

#top.top2{
    margin-top: 0px;
}


.top-title{
    text-align: center;
    margin-bottom: 20px;
}

.search-card{
    cursor: pointer;

}

.search-card .card-img-top{
    cursor: pointer;
    /* -webkit-filter: grayscale(60%);
    filter: grayscale(60%); */
}

.search-card:hover{
    background: #f1f1f1;
    color: black !important;
}


`;