import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as JSZip from 'jszip';
import * as JSZipUtils from '../../assets/js/jszip-utils.js';

// RXJS
import { map, catchError, subscribeOn } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Client, ClientObj } from '../models/client.model';

// Firebase Storage
import { AngularFireStorage } from 'angularfire2/storage';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { SetClientAction } from '../pages/pages.actions';
import { Subscription } from 'rxjs';

// Firebase
import { AngularFireDatabase } from '@angular/fire/database';

import * as  pngToIco from 'png-to-ico';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService implements OnDestroy {

  private url = "https://hogar-inn.firebaseio.com/";

  subscriptionRedux: Subscription = new Subscription();

  client: Client = new Client({});

  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage,
    private store: Store<AppState>,
    private db: AngularFireDatabase
  ) {
    this.subscriptionRedux = this.store.select('orders')
      .subscribe( res => {
        if( res.client ) {
          this.client = res.client;
        }
        
      })
  }

  ngOnDestroy(){
    this.subscriptionRedux.unsubscribe();
  }

  setClient( client: Client ){
    return this.http.post(`${this.url}orders.json`, client)
      .pipe( 
        map( ( res: any )  => {
          client.id = res.name;
            this.updateClient( client )
              .subscribe( ( res2: any ) => {
                localStorage.setItem('client', JSON.stringify(client) );
        
              })
          return res;
        })
      )
  }

  setLogoClient( image, imageName ){
    if( image ) {
      return this.storage.upload(imageName, image );
    } else {
      return new Promise( (resolve, reject )  => {
          resolve();
      });
    }
  }

  createFavicon(  ){
     return new Promise( (resolve, reject ) => {
      pngToIco( this.client.icon_white )
          .then( buf => {
            // @ts-ignore
            this.setLogoClient( buf, `${ this.client.id }-favicon`).then( () => {
              resolve();
            }) 
          })
          .catch( ( err: any ) => {
            reject(err);
          })
         
     });
  }



  // Take an image URL, downscale it to the given width, and return a new image URL.

  downscaleImage(dataUrl) {
     return new Promise( (resolve, reject ) => {
          new JSZip.external.Promise(function (res, rej) {
            JSZipUtils.getBinaryContent(dataUrl,  function(err, data) {
                if (err) {
                    rej(err);
                } else {
                    res(data);
                }
            });
          }).then(function (data : any) {
        
            var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');
        
            // set its dimension to target size
            canvas.width = 256;
            canvas.height = 256;
        
            // draw source image into the off-screen canvas:
        
            var buffer = ctx.getImageData(0,0,ctx.canvas.width, ctx.canvas.height).data.buffer;
            
            var array = new Uint8ClampedArray(buffer);
            
            var image = new ImageData(array, ctx.canvas.width, ctx.canvas.height);
            
            resolve(image);        
            
          })
     });
  }


  updateClient( client: Client ) {
    return this.http.put(`${this.url}orders/${client.id}.json`, client)
      .pipe( 
        map( ( res: any )  => {
          localStorage.setItem('client', JSON.stringify(client) );
          return res;
        })
      )
  }



  getUrlImage(filePath, type){
     return new Promise( (resolve, reject ) => {
          const ref = this.storage.ref(filePath);
          const downloadURL = ref.getDownloadURL().subscribe( url => {
      
            if( type == 'logo-white') {
              this.client.logo_white = url;
            } else if( type == 'logo-black') {
              this.client.logo_dark = url;
            } else if( type == 'icon-white') {
              this.client.icon_white = url;
            } else if( type == 'favicon') {
              this.client.favicon = url;
            } else {
              this.client.icon_dark = url;
            }
      
            this.updateClient( this.client )
              .subscribe( () => {
                // console.log('get url', this.client);
                this.store.dispatch( new SetClientAction(this.client));
                resolve();
              })
          });
       
     });
  }

  getFalseClientData(){
    let client: ClientObj = {
      slogan: 'Transformando el mundo inmobiliario junto a ti',
      mission: 'Ser la primera opción para las inmobiliarias y agentes inmobiliarios, ofreciendo servicios que innoven la administración y control de sus operaciones, con productos seguros y de alta calidad, con el objetivo de optimizar sus estrategias de marketing para incrementar ventas y reducir costos',
      vision: 'En el 2020 ser una empresa líder en el mercado de sistemas inmobiliarios en el territorio nacional mexicano, integrando productos que optimicen las principales áreas de una empresa (operaciones, marketing, ventas, finanzas y atención a cliente) con el objetivo de que el consumidor final obtenga la satisfacción total',
      objective: '',
      primary_color: 'black',
      text_primary: 'white',
      secondary_color: '#e4cb00',
      text_secondary: 'black',
      background_color: 'white',
      text_background: 'black',
      business: 'Hogar Inn',
      email: 'ventas@hogarinn.com',
      phone: '9999552233',
      facebook: 'https://www.facebook.com/HogarInn',
      icon_dark: './assets/img/brand/icono-fondo-negro.svg',
      icon_white: './assets/img/brand/icono-fondo-blanco.svg',
      logo_dark: './assets/img/brand/logo-fondo-negro.svg',
      logo_white: './assets/img/brand/logo-h.svg'
    }

    return new Client(client);
  }

  getClientById( id){
    return this.http.get(`${this.url}orders/${id}.json`)
    .pipe(  map( ( res: Client )  => {
      localStorage.setItem('client', JSON.stringify(res) );
      this.store.dispatch( new SetClientAction(res) );
      return  res;
    }),
    catchError( err => {
      return throwError( err );
    }));
  }

  getClients(){
    return this.http.get(`${this.url}orders.json`)
      .pipe(  map( ( res: Client[] )  => {
        if( res ){
          res = Object.values(res);
        }
        return  res;
      }),
      catchError( err => {
        return throwError( err );
      }));
  }


  getClientsWaiting(){
    return this.http.get(`${this.url}orders.json`)
      .pipe(  map( ( res: Client[] )  => {
        if( res ){
          res = Object.values(res);
          res = res.filter( data => {
            if( data.status == 'waiting' ){
              return data;
            }
          });
       
        }
         
          return  res;
      }),
      catchError( err => {
        return throwError( err );
      }));
  }

  getClientsProcess(){
    return this.http.get(`${this.url}orders.json`)
      .pipe(  map( ( res: Client[] )  => {
        if( res ){
          res = Object.values(res);
          res = res.filter( data => {
            if( data.status == 'process' ){
              return data;
            }
          });
        }
       
          return  res;
      }),
      catchError( err => {
        return throwError( err );
      }));
  }

  getClientsDone(){
    return this.http.get(`${this.url}orders.json`)
      .pipe(  map( ( res: Client[] )  => {
        if( res ){
          res = Object.values(res);
          res = res.filter( data => {
            if( data.status == 'done' ){
              return data;
            }
          });
        }
       
          return  res;
      }),
      catchError( err => {
        return throwError( err );
      }));
  }

  checkElementsChange() {
    let rootRef = this.db.list('orders');
    const susbscribeRoof = rootRef.snapshotChanges();
    return susbscribeRoof;
  }



 
}
