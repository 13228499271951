import * as fromUI from './ui.actions';

export interface uiState {
    ui: UI
}

const initState: uiState = {
    ui: {
        loading: 0,
        menu: false
    }
};

export function uiReducer( state = initState, action: fromUI.acciones ): uiState {
    switch ( action.type ) {
        case fromUI.SET_LOADING_PORCENTAGE:
            return {
                ui: {
                    ... state.ui,
                    loading: action.loading
                }
            };
        case fromUI.SET_MENU_STATUS:
                return {
                    ui: {
                        ... state.ui,
                        menu: action.menu
                    }
                };
        default:
            return state;
        
    }
}

export interface UI {
    loading: number,
    menu: boolean
}

