export let search_css_3 = `
.property-card {
    border-bottom: 1px solid gray;
}

.img-fluid-card {
    max-width: 800px !important;
    width: 100% !important;
}

.icon-sm {
    height: 19px;
    width: 19px;
}


.content-height {
    min-height: calc( 100vh - 60px - 222px);
}
`;