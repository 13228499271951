import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd, NavigationStart, NavigationEnd } from '@angular/router';

import { filter, map } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.css']
})
export class CotizadorComponent implements OnInit, OnDestroy {

  tabSelected = '';
  client;

  subscriptionClients: Subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<AppState>
  ) { }

  ngOnInit() {

    this.store.select('orders')
      .subscribe( res => {
        this.client = res.client;
      })
    this.defineInitLink();

    this.detectChangeLink();
  }

  ngOnDestroy() {
    this.subscriptionClients.unsubscribe();
  }
  

  defineInitLink(){
    return new Promise( (resolve,reject) => {
      const url = this.router.url;
      this.defineLinkName( url );
      resolve();      
    })
  }

  defineLinkName( url: string ){
    if(url.includes('cliente')){
      this.tabSelected = 'cliente'
      return;
    }

    if(url.includes('plan')){
      this.tabSelected = 'plan'
      return;
    }

    if(url.includes('dise%C3%B1o')){
      this.tabSelected = 'diseño'
      return;
    }

    if(url.includes('paginas')){
      this.tabSelected = 'paginas'
      return;
    }


    if(url.includes('data')){
      this.tabSelected = 'data'
      return;
    }

    if(url.includes('confirmar')){
      this.tabSelected = 'confirmar'
      return;
    }   
  }

  detectChangeLink(){
    this.router.events.subscribe( event => {
      if(event instanceof NavigationEnd ) {
        this.defineLinkName( event.url );
      }
    })
  }

}
