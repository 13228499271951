
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebpagesComponent } from './pages/webpages/webpages.component';
import { CotizadorComponent } from './pages/cotizador/cotizador.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoginComponent } from './pages/shared/login/login.component';


const routes: Routes = [
  {
      path: '',
      component: WebpagesComponent
  },
  {
      path: 'cotizador',
      component: CotizadorComponent,
      loadChildren: './pages/cotizador/cotizador.module#CotizadorModule'
  },
  {
    path: 'plantillas',
    component: TemplatesComponent,
    loadChildren: './pages/templates/templates.module#TemplatesModule'
  },
  {
    path: 'ordenes',
    canActivate: [AuthGuardService],
    component: OrdersComponent,
    loadChildren: './pages/orders/orders.module#OrdersModule'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot( routes )
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutesModule { }
