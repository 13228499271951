import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';
import { Client } from 'src/app/models/client.model';
import { FilesManageService } from 'src/app/services/files-manage.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy {

  loading: number;

  loadingData = false;

  clients: Client[];
  pageSelected;
  activePage;
  notElements = false;

  waitingOrders = 0;
  processOrders = 0;
  completedOrders = 0;

  subscriptionUI: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private databaseService: DatabaseService,
    private filesManageService: FilesManageService,
    private store: Store<AppState>
  ) {

    this.subscriptionUI = this.store.select('ui')
      .subscribe( res => {
        this.loading = res.ui.loading;
      })

    this.route.queryParams
    .subscribe( res => {
      this.loadingData = true;
      this.activePage = res.tipo;
      this.databaseService.checkElementsChange()
      .subscribe( () => {
        this.getInformation(this.activePage)
          .then( () => {
            this.loadingData = false;
            if( !this.clients ){
              this.notElements = true;
            } else {
              this.notElements = false;
            }
     
            this.getTotalNumber();
          })
     
      })
    })
  }
  

  getTotalNumber(){


    this.databaseService.getClients()
      .subscribe( res => {
        this.waitingOrders = 0;
        this.processOrders = 0;
        this.completedOrders = 0;
        if( res ) {
          res.forEach( client => {
  
            switch ( client.status ) {
              case 'waiting':
                this.waitingOrders += 1;
                break;
              case 'process':
                this.processOrders += 1;
                break;
              case 'completed':
                this.completedOrders += 1;
                break;
              default:
                break;
            }
          })
        }
  
     
      })

   
  }

  getInformation(type){
     return new Promise( (resolve, reject ) => {
        switch ( type ) {
          case 'en_espera':
            this.pageSelected = 'En espera';
            this.databaseService.getClientsWaiting()
              .subscribe( res => {
                this.clients = res;
                resolve();
              });
            break;
          case 'en_proceso':
              this.pageSelected = 'En proceso';
              this.databaseService.getClientsProcess()
                .subscribe( res => {
                  this.clients = res;
                  resolve();
                });
              break;
          case 'completadas':
              this.pageSelected = 'Completadas';
              this.databaseService.getClientsDone()
                .subscribe( res => {
                  this.clients = res;
                  resolve();
                });
              break;
          default:
    
              this.pageSelected = 'En espera';
                this.activePage = 'en_espera'
                this.databaseService.getClientsWaiting()
                .subscribe( res => {
                  this.clients = res;
                  resolve();
                });
              break;
        }
      
     });
  }


  ngOnInit() {
  }

  ngOnDestroy(){
    this.subscriptionUI.unsubscribe();
  } 

  selectedOneClient( client: Client ) {
    localStorage.setItem('client', JSON.stringify(client));
    window.open(`/cotizador`, '_blank');
  }

  exportFile( client: Client ){
    this.filesManageService.createPageZipTemplate(client)
  }



  restarSystem(){
    localStorage.removeItem('client');
    window.open(`/cotizador`, '_blank');
    // this.router.navigate([]).then(result => {  });
  }

}
