import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {

  id;

  constructor(
    private route: ActivatedRoute,
    private databaseService: DatabaseService
  ) {
    this.route.queryParams
      .subscribe( res => {
        if(res) {
          if( res.id ){
            this.id = res.id;
            this.getClient();
          }
        }
      })
  }

  ngOnInit() {
  }

  getClient(){
    this.databaseService.getClientById( this.id ) 
      .subscribe();
  }

}
