export let header_css_3 = `
.navbar{
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    padding: .5rem 1rem 0px 1rem !important;
    z-index: 2;
}

#nav-filter .container{
    width: calc( 100% - 2rem );
}

.nav-link:hover{
    color: #000000 !important;
    font-weight: initial;
}

.social-media{
    margin: 0px 10px;
}

.border-image{
    display: none !important;
}


@media (min-width: 576px) {
    .navbar{

        padding: 0px !important;

    }
    
}

@media (min-width: 768px) {
    
}

@media (min-width: 992px) {
    .nav-link{
        padding: 1.5rem 1rem !important;
    }

    .social-media{
        margin: auto;
    }
}

@media (min-width: 1200px) {

}
`;