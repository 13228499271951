import * as fromAccount from './components.actions';

export interface AccountState {
    design: string;
}

let designValue;

if( localStorage.getItem('client') ) {
    designValue = JSON.parse(localStorage.getItem('client')).design;
} else {
    designValue = 1;
}



const initState: AccountState = {
    design: designValue
};

export function accountReducer( state = initState, action: fromAccount.acciones ): AccountState {
    switch ( action.type ) {
        case fromAccount.SET_DESIGN_SELECTED:
            return {
                design:  action.design
            };
        default:
            return state;
    }
}

