import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import * as JSZipUtils from '../../assets/js/jszip-utils.js';


import { saveAs } from 'file-saver';
import { Client } from '../models/client.model';
import { animation_js_file, index_js_file } from '../data/templatesData/javascripts';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer.js';
import { SetUILoadingAction } from '../ui/ui.actions.js';

import { variables } from '../data/templatesData/variables.js';
import { css } from '../data/templatesData/exports';
import { views } from '../data/templatesData/exports';



@Injectable({
  providedIn: 'root'
})
export class FilesManageService {

  constructor(
    private http: HttpClient, 
    private store: Store<AppState>
  ) { }

  createPageZipTemplate( client: Client ){
    let zip = new JSZip();

    // Create javascript files
    this.createJavascript( zip )
      .then( () => this.createCSS( zip, client ))
      .then( () =>  this.getFiles(zip, this.defineAssetsToExport(client) ))
      .then( () =>  this.createViews( zip, client ) )
      .then( () => {
         
         // Set the loading on 100%
         this.store.dispatch( new SetUILoadingAction(0) );
         return this.downloadListVariables( client );
      })
  }

  createJavascript( zip ){
    // Create css styles files
     return new Promise( (resolve, reject ) => {
        // Set the loading on 0%
        this.store.dispatch( new SetUILoadingAction(0) );

        let folder_javascripts = zip.folder('javascripts');
        folder_javascripts.file('animation.js', `${ animation_js_file }` );
        folder_javascripts.file('index.js', `${ index_js_file }`);
         resolve();
     });
  }

  createCSS( zip, client ){
     return new Promise( (resolve, reject ) => {
        let folder_styles = zip.folder('styles');
        folder_styles.file('main.scss', this.defineCssToExport(client).main);
        folder_styles.file('general.css', this.defineCssToExport(client).general);
        folder_styles.file('index.css', this.defineCssToExport(client).index);
        folder_styles.file('header.css', this.defineCssToExport(client).header);
        folder_styles.file('footer.css', this.defineCssToExport(client).footer);
        folder_styles.file('search.css', this.defineCssToExport(client).search);
        folder_styles.file('property.css', this.defineCssToExport(client).property);
        folder_styles.file('animate.css', this.defineCssToExport(client).animate);
         resolve();
     });
  }

  getFiles( zip, files: any ){
    return new Promise( (resolve, reject ) => {
         
        // Create assets files 
        let folder_assets = zip.folder('assets');

        // Set the loading on 50%
         this.store.dispatch( new SetUILoadingAction(50) );

         files.forEach( (file, index) => {
             new JSZip.external.Promise(function (res, rej) {
              JSZipUtils.getBinaryContent(file.path+file.name, function(err, data) {
                   if (err) {
                       rej(err);
                   } else {
                       res(data);
                   }
               });
             }).then(function (data : any) {
                 folder_assets.file(file.name, data);
    
                 if( ( index+1 ) == files.length ) {
                   setTimeout(() => {
                     resolve();
                   }, 3000);
               
                 }
             })
        })
    });
 }

  createViews( zip, client: Client ){
     return new Promise( (resolve, reject ) => {
          // Set the loading on 75%
          this.store.dispatch( new SetUILoadingAction(75) );

          let folder_views = zip.folder('views');
          folder_views.file('contact.liquid', this.defineViewsToExport(client).contact);
          folder_views.file('extra.liquid', this.defineViewsToExport(client).extra);
          folder_views.file('footer.liquid', this.defineViewsToExport(client).footer);
          folder_views.file('header.liquid', this.defineViewsToExport(client).header);
          folder_views.file('index.liquid', this.defineViewsToExport(client).index);
          folder_views.file('properties.liquid', this.defineViewsToExport(client).properties);
          folder_views.file('property.liquid', this.defineViewsToExport(client).property);
   

          zip.generateAsync({type: 'blob'})
              .then( (content) => {     
                saveAs( content, `HogarInn_${client.business}.zip`);
                resolve(); 
              })

     });
  }
  
  defineCssToExport( client: Client ){

    switch( client.design ) {
      case '3':
        return { 
          general: css.general_css_file,
          main: css.main_css_3, 
          index: css.index_css_3, 
          header: css.header_css_3, 
          footer: css.footer_css_3, 
          search: css.search_css_3, 
          property: css.property_css_3, 
          animate: css.animate_css_file
        };

      case '1':
          return { 
            general: css.general_css_file,
            main: css.main_css_1, 
            index: css.index_css_1, 
            header: css.header_css_1, 
            footer: css.footer_css_1, 
            search: css.search_css_1, 
            property: css.property_css_1, 
            animate: css.animate_css_file
          };
      default:
        break;
    }
  }

  defineAssetsToExport( client: Client ) {
    switch( client.design ) {
      case '3':
        return [
          { path: client.icon_white,
            name: 'icon-white.png'},
          { path: client.icon_dark,
            name: 'icon-dark.png'},
          { path: client.logo_white,
            name: 'logo-white.png'},
          { path: client.logo_dark,
            name: 'logo-dark.png'},
          { path: client.favicon,
            name: 'favicon.ico'}
        ];
      case '1':
        return [
          { path: client.icon_white,
            name: 'icon-white.png'},
          { path: client.icon_dark,
            name: 'icon-dark.png'},
          { path: client.logo_white,
            name: 'logo-white.png'},
          { path: client.logo_dark,
            name: 'logo-dark.png'},
          { path: client.favicon,
            name: 'favicon.ico'}
        ];
      default:
        break;
    }
  }

  defineViewsToExport( client: Client ) {
    switch( client.design ) {
      case '3':
        return { 
          contact: views.contact_view_3, 
          extra: views.extra_view_3, 
          header: views.header_view_3( client ),
          footer: views.footer_view_3( client ), 
          properties: views.properties_view_3( client ), 
          property: views.property_view_3( client ), 
          index: views.index_view_3(client)
        };
      case '1':
          return { 
            contact: views.contact_view_1, 
            extra: views.extra_view_1, 
            header: views.header_view_1( client ),
            footer: views.footer_view_1( client ), 
            properties: views.properties_view_1( client ), 
            property: views.property_view_1( client ), 
            index: views.index_view_1(client)
          };
      default:
        break;
    }
  }

  downloadListVariables( client: Client ){
    return new Promise( (resolve, reject ) => {

    let variablesContent = "data:text/csv;charset=utf-8," 
        + variables( client );
      var encodedUri = encodeURI(variablesContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "variables.text");
      document.body.appendChild(link); // Required for FF
      
      link.click(); // This will download the data file named "my_data.csv".
        resolve();
    });
  }
}
