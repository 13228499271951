export let index_js_file = `
// FILTER BUTTON
var menu = $('#menu');
var contact = $('#contacto');
var content = $('#content');
var inicio = $('#inicio');

$('body').on("click", "#filter-button", function() {

    menu.removeClass('d-none');
    menu.addClass('show-filter');
    contact.addClass('d-none');
    contact.removeClass('d-flex');
    content.addClass('d-none');
    inicio.addClass('d-none');
});

// SHARED BUTTON

$('body').on("click", "#shared-button", function() {
    menu.removeClass('d-none');
    menu.addClass('show-filter');
    contact.addClass('d-none');
    contact.removeClass('d-flex');
    content.addClass('d-none');
    inicio.addClass('d-none');

});

// COMPLEMENTS BUTTONS
$('body').on("click", "#button-search", function() {
    menu.addClass('d-none');
    menu.removeClass('show-filter');
    contact.removeClass('d-none');
    contact.addClass('d-flex');
    content.removeClass('d-none');
    inicio.removeClass('d-none');
});

$('body').on("click", "#button-close", function() {
    menu.addClass('d-none');
    menu.removeClass('show-filter');
    contact.removeClass('d-none');
    contact.addClass('d-flex');
    content.removeClass('d-none');
    inicio.removeClass('d-none');
});

// SET URLS
// Check this link for the metadata images
// https://stackoverflow.com/questions/19778620/provide-an-image-for-whatsapp-link-sharing
// Check this link for link to share with facebook
// https://stackoverflow.com/questions/16463030/how-to-add-facebook-share-button-on-my-website

if (section == 'property') {
    var whatsapp_btn = document.getElementById("whatsapp-btn");
    whatsapp_btn.setAttribute("href", 'https://wa.me/?text=' + document.URL);

    var whatsapp_btn_mobile = document.getElementById("whatsapp-btn-mobile");
    whatsapp_btn_mobile.setAttribute("href", 'https://wa.me/?text=' + document.URL);

    var email_btn = document.getElementById("email-btn")
    email_btn.setAttribute("href", 'mailto:?subject=Mira la siguiente propiedad de BRBL&body= Bienes Raices Bien Legal tiene la siguiente propiedad que te puede interesar: ' + document.URL);

    var email_btn_mobile = document.getElementById("email-btn-mobile")
    email_btn_mobile.setAttribute("href", 'mailto:?subject=Mira la siguiente propiedad de BRBL&body= Bienes Raices Bien Legal tiene la siguiente propiedad que te puede interesar: ' + document.URL);

    var fb_btn = document.getElementById("facebook-btn")
    fb_btn.setAttribute("href", 'https://www.facebook.com/sharer/sharer.php?u=' + document.URL);

    var fb_btn_mobile = document.getElementById("facebook-btn-mobile")
    fb_btn_mobile.setAttribute("href", 'https://www.facebook.com/sharer/sharer.php?u=' + document.URL);
}


// CHANGE OPTIONS AND SET NEW CITIES
function changeCityOption() {
    var citySelected = document.getElementById("citySelect");
    var stateSelectedValue = document.getElementById("stateSelect").value;
    for (var i = 0; i < locations.length; i++) {
        if (locations[i].name == stateSelectedValue) {

            for (i2 = citySelected.length - 1; i2 >= 0; i2--) {
                citySelected.remove(i2);
            }

            var newCities = ['<option value="">Todas las ciudades</option>'];

            for (i3 = 0; i3 < locations[i].cities.length; i3++) {
                newCities.push('<option value="' + locations[i].cities[i3].name + '">' + locations[i].cities[i3].name + '</option>');
            }
            citySelected.innerHTML = newCities;
        } else {
            var newCities = ['<option value="">Todas las ciudades</option>'];
            citySelected.innerHTML = newCities;
        }
    }
}

function changeCityOptionMobile() {
    var citySelected = document.getElementById("citySelect_mobile");
    var stateSelectedValue = document.getElementById("stateSelect_mobile").value;
    for (var i = 0; i < locations.length; i++) {
        if (locations[i].name == stateSelectedValue) {

            for (i2 = citySelected.length - 1; i2 >= 0; i2--) {
                citySelected.remove(i2);
            }

            var newCities = ['<option value="">Todas las ciudades</option>'];

            for (i3 = 0; i3 < locations[i].cities.length; i3++) {
                newCities.push('<option value="' + locations[i].cities[i3].name + '">' + locations[i].cities[i3].name + '</option>');
            }
            citySelected.innerHTML = newCities;
        } else {
            var newCities = ['<option value="">Todas las ciudades</option>'];
            citySelected.innerHTML = newCities;
        }
    }
}

//================================================
//====== Change number of posible views  
//================================================

function changeNumberOfViews(value) {
    if (value) {
        var url = window.location.href;
        if (url.indexOf('per_page=96') > -1) {
            url = url.replace("per_page=96", 'per_page=' + value);
            window.location.href = url;
            return;
        } else if (url.indexOf('per_page=48') > -1) {
            url = url.replace("per_page=48", 'per_page=' + value);
            window.location.href = url;
            return;
        } else if (url.indexOf('per_page=24') > -1) {
            url = url.replace("per_page=24", 'per_page=' + value);
            window.location.href = url;
            return;
        } else if (url.indexOf('per_page=12') > -1) {
            url = url.replace("per_page=12", 'per_page=' + value);
            window.location.href = url;
            return;
        } else if (url.indexOf('per_page=6') > -1) {
            url = url.replace("per_page=6", 'per_page=' + value);
            window.location.href = url;
            return;
        } else if (url.indexOf('per_page=10') > -1) {
            url = url.replace("per_page=10", 'per_page=' + value);
            window.location.href = url;
            return;
        } else if (url.indexOf('?') > -1) {
            window.location.href = url + '&per_page=' + value;
            return;
        } else {
            window.location.href = url + '?per_page=' + value;
            return;
        }

    }
}



//================================================
//====== Mission, Vision and Objective  
//================================================

function clickButton1() {
    $(".company-content .text-content").text(mision_content);
    if (!$(this).hasClass('active')) {
        $(".company-buttons-container .button-1").addClass('active');
        $(".company-buttons-container .button-2").removeClass('active');
        $(".company-buttons-container .button-3").removeClass('active');
    }
}

function clickButton2() {
    $(".company-content .text-content").text(vision_content);
    if (!$(this).hasClass('active')) {
        $(".company-buttons-container .button-2").addClass('active');
        $(".company-buttons-container .button-1").removeClass('active');
        $(".company-buttons-container .button-3").removeClass('active');
    }
}

function clickButton3() {
    $(".company-content .text-content").text(objectivos_content);
    if (!$(this).hasClass('active')) {
        $(".company-buttons-container .button-3").addClass('active');
        $(".company-buttons-container .button-1").removeClass('active');
        $(".company-buttons-container .button-2").removeClass('active');
    }
}

`;

export let animation_js_file = `
var cargado = false;
var animation = true;

setTimeout(function() {
    animation = false;
    showData();
}, 300);

function showData() {

    setTimeout(function() {
        if( document.getElementById("inicio") ) {
            document.getElementById("inicio").classList.add('show');
        }
        
    }, 800);

    setTimeout(function() {
        if( document.getElementById("wrap") ) {
            document.getElementById("wrap").classList.add('show');
        }
       
    }, 1300);
}

// =========
// SCROLL
// =========
var titulo = false;
var image_card = false;
var second_image_card = false;
var mision = false;
var second_title = false;
var third_image_card = false;
var contacto = false;

if (window.location.hash) {
    showAll()
    var location = window.location.hash;
    $.scrollTo($(location), { duration: 0 });
} else {
    if ($(window).scrollTop() <= 20) {
        if (window.location.href.indexOf('propiedades') > -1) {
            showAll()
        } else if (window.location.href.indexOf('propiedad') > -1) {
            showAll()
        } else if (window.location.href.indexOf('contacto') > -1) {
            showAll()
        } else if (window.location.href.indexOf('extra') > -1) {
            showAll()
        } else {
            onTheTop();
        }
    } else {
        showAll()
    }
}

function showAll() {
    titulo = true;
    image_card = true;
    second_image_card = true;
    mision = true;
    second_title = false;
    third_image_card = true;
    contacto = true;

    
    if ( document.querySelector('contacto') ) {
        var contacto_element = document.getElementById("contacto");
        contacto_element.classList.add('animated', 'fadeIn')
    }
   

    if (section == 'index') {

        if (document.querySelector('#titulo')) {
            var titulo_element = document.querySelector('#titulo');
            titulo_element.classList.add('animated', 'fadeIn');
        }
        if( document.querySelector('#image-card') ) {
            var image_card_element = document.querySelector('#image-card');
            image_card_element.classList.add('animated', 'fadeIn')
        }

        if( document.querySelector('.second-image-card') ){
            var second_image_card_element = document.querySelector('.second-image-card');
            second_image_card_element.classList.add('animated', 'fadeIn');
        }

        if( document.querySelector('#mision') ){
            var mision_elements = document.querySelector('#mision');
            mision_elements.classList.add('animated', 'fadeIn');
        }
       
        if (document.querySelector('.second-title')) {
            var second_title_element = document.querySelector('.second-title')
            second_title_element.classList.add('animated', 'fadeIn')
        }

        if( document.querySelector('.third-image-card') ) {
            var third_image_card_element = document.querySelector('.third-image-card')
            third_image_card_element.classList.add('animated', 'fadeIn')
        }
        
    }
}

function onTheTop() {
    $('#wrap').scroll(function() {

        if (section == 'index') {
            $('#titulo').each(function() {
                if (isScrolledIntoView($(this), 0) && !titulo) {
                    titulo = true;
                    if (document.querySelector('#titulo')) {
                        var element = document.querySelector('#titulo')
                        element.classList.add('animated', 'fadeInUp')
                    }
                }

                if (isScrolledIntoView($(this), 0) && !image_card) {
                    image_card = true;
                    if( document.querySelector('#image-card') ) {
                        var element_body = document.querySelector('#image-card');
                        element_body.classList.add('animated', 'fadeInUp')
                    }
                    
                }
            });

            $('.second-image-card').each(function() {
                if (isScrolledIntoView($(this), 0) && !second_image_card) {
                    second_image_card = true;
                    if( document.querySelector('.second-image-card') ) {
                        var element_body = document.querySelector('.second-image-card');
                        element_body.classList.add('animated', 'fadeInUp');
                    }
                    
                }
            });

            $('#mision').each(function() {
                if (isScrolledIntoView($(this), 0) && !mision) {
                    mision = true;
                    if( document.querySelector('#mision') ){
                        var elementTitle = document.querySelector('#mision');
                        elementTitle.classList.add('animated', 'fadeInUp');
                    }
                    
                }

                if (isScrolledIntoView($(this), 0) && !second_title) {
                    second_title = true;
                    if (document.querySelector('.second-title')) {
                        var elementTitle = document.querySelector('.second-title')
                        elementTitle.classList.add('animated', 'fadeInUp')
                    }
                }

                if (isScrolledIntoView($(this), 0) && !third_image_card) {
                    third_image_card = true;
                    if( document.querySelector('.third-image-card') ) {
                        var elementTitle = document.querySelector('.third-image-card');
                        elementTitle.classList.add('animated', 'fadeInUp');
                    }
                    
                }
            });
        }

        $('#contacto').each(function() {
            if (isScrolledIntoView($(this), 0) && !contacto) {
                contacto = true;
                if( document.querySelector('#contacto') ) {
                    var element = document.querySelector('#contacto');
                    element.classList.add('animated', 'fadeIn');
                }
                
            }
        });
    });
}

function isScrolledIntoView(elem, extra) {
    var $elem = $(elem);
    var $window = $(window);

    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height() + extra;

    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();

    //  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    return ((elemBottom <= docViewBottom));
}
`;
