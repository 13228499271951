import * as fromAccount from './pages.actions';
import { Client } from '../models/client.model';

export interface AccountState {
    client: Client;
}

const clientStorage = JSON.parse(localStorage.getItem('client'));

const initState: AccountState = {
    client: clientStorage
};

export function accountReducer( state = initState, action: fromAccount.acciones ): AccountState {
    switch ( action.type ) {
        case fromAccount.SET_CLIENT:
            return {
                client: {
                   ... action.client
                }
            };
        case fromAccount.UNSET_CLIENT:
            return {
                client:  null
            }
        default:
            return state;
    }
}

