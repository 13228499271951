import { Component, OnInit } from '@angular/core';

// Import alerts
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  code: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  makeLogin(){
    if( this.email == 'eduardo.torre@hogarinn.com' || this.email == 'cesar.roman@hogarinn.com' ) {
      if( this.code === 'chicharitoCUM' ) {
        localStorage.setItem('key', 'ksad2lsfkd*23sadadqw(//()AS(DOAInm');
        this.router.navigate(['/ordenes']);
      } else {
        Swal.fire({
          title: '¡Problema!',
          text: 'No es posible iniciar sesión',
          type: 'warning',
          confirmButtonText: 'Continuar',
        });
        this.router.navigate(['/cotizador']);
      }
    } else {
      Swal.fire({
        title: '¡Problema!',
        text: 'No es posible iniciar sesión',
        type: 'warning',
        confirmButtonText: 'Continuar',
      });
      this.router.navigate(['/cotizador']);
    }
  }

}
