import { main_css_1 } from '../templatesData/aura/css/main';
import { index_css_1 } from '../templatesData/aura/css/index.js';
import { header_css_1 } from '../templatesData/aura/css/header.js';
import { footer_css_1 } from '../templatesData/aura/css/footer.js';
import { search_css_1 } from '../templatesData/aura/css/search.js';
import { property_css_1 } from '../templatesData/aura/css/property.js';

import { header_view_1 } from '../templatesData/aura/views/header.js';
import { contact_view_1 } from '../templatesData/aura/views/contact.js';
import { extra_view_1 } from '../templatesData/aura/views/extra.js';
import { footer_view_1 } from '../templatesData/aura/views/footer.js';
import { properties_view_1 } from '../templatesData/aura/views/properties.js';
import { property_view_1 } from '../templatesData/aura/views/property.js';
import { index_view_1 } from '../templatesData/aura/views/index.js';

import { contact_view_3 } from '../templatesData/clasico/views/contact.js';
import { extra_view_3 } from '../templatesData/clasico/views/extra.js';
import { header_view_3 } from '../templatesData/clasico/views/header.js';
import { footer_view_3 } from '../templatesData/clasico/views/footer.js';
import { properties_view_3 } from '../templatesData/clasico/views/properties.js';
import { index_view_3 } from '../templatesData/clasico/views/index.js';
import { property_view_3 } from '../templatesData/clasico/views/property.js';

import { main_css_3 } from '../templatesData/clasico/css/main.js';
import { index_css_3 } from '../templatesData/clasico/css';
import { header_css_3 } from '../templatesData/clasico/css/header';
import { footer_css_3 } from '../templatesData/clasico/css/footer';
import { search_css_3 } from '../templatesData/clasico/css/search';
import { property_css_3 } from '../templatesData/clasico/css/property';

import { animate_css_file, general_css_file } from '../templatesData/css.js';


export let css = {
    animate_css_file,
    general_css_file,

    main_css_1,
    index_css_1,
    header_css_1,
    footer_css_1,
    search_css_1,
    property_css_1,
    main_css_3,
    index_css_3, 
    header_css_3, 
    footer_css_3,
    search_css_3,
    property_css_3
}

export let views = {
    header_view_1, 
    contact_view_1,
    extra_view_1, 
    footer_view_1, properties_view_1, property_view_1, index_view_1,
    contact_view_3, extra_view_3, header_view_3, footer_view_3, properties_view_3, index_view_3, property_view_3
}